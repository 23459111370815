export const BirthTypes = {
  SOLAR : 'solar',
  LUNAR : 'lunar',
};

export const TEST_TYPES = {
  DAILY: 'dailyTest',
  YEAR: '2025Test',
  DOB: 'dobTest',
};

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
