import useAuth from 'hooks/useAuth';
import WebsiteLayout from 'layouts/WebsiteLayout';
import React, { Suspense, Fragment, lazy, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import NotFound from 'views/errors/notFound';

export const renderRoutes = (routes = []) => {
  const { isLoggedIn, user } = useAuth();

  useEffect(() => {
    if (!window.location.pathname.split('/').includes('account') && user?.email && !user?.dob) {
      window.location.href = '/account?redirect=start';
    }
  }, [user]);

  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          let Element = route.element;

          if (route.isPrivate && !isLoggedIn()) {
            Element = NotFound;
          }

          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Guard>
                  <Layout>
                    {route.isPrivate ? (
                      <PrivateRoute>
                        {route.routes ? renderRoutes(route.routes) : <Element />}
                      </PrivateRoute>
                    ) : route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Element />
                    )}
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  )
};

const routes = [
  {
    exact: 'true',
    path: '/signin',
    element: lazy(() => import('views/auth/signIn')),
    isPrivate: false,
  },
  {
    exact: 'true',
    path: '/signup',
    element: lazy(() => import('views/auth/signUp')),
    isPrivate: false,
  },
  {
    exact: 'true',
    path: '/account',
    element: lazy(() => import('views/auth/user')),
    isPrivate: true,
  },
  {
    exact: 'true',
    path: '/edit-info',
    element: lazy(() => import('views/edit-info')),
    isPrivate: true,
  },
  {
    exact: 'true',
    path: '/start',
    element: lazy(() => import('views/start')),
    isPrivate: true,
  },
  {
    exact: 'true',
    path: '/fortune',
    element: lazy(() => import('views/fortune')),
    isPrivate: true,
  },
  {
    exact: 'true',
    path: '/fortune-by-year',
    element: lazy(() => import('views/fortune-by-year')),
    isPrivate: true,
  },
  {
    exact: 'true',
    path: '/fortune-by-year/cards',
    element: lazy(() => import('views/fortune-by-year/cards')),
    isPrivate: true,
  },
  {
    exact: 'true',
    path: '/daily-fortune',
    element: lazy(() => import('views/daily-fortune')),
    isPrivate: true,
  },
  {
    exact: 'true',
    path: '/daily-fortune/cards',
    element: lazy(() => import('views/daily-fortune/cards')),
    isPrivate: true,
  },
  {
    exact: 'true',
    path: '/fortune/cards',
    element: lazy(() => import('views/fortune/cards')),
    isPrivate: true,
  },
  {
    path: '/terms-of-service',
    exact: 'true',
    element: lazy(() => import('views/terms-of-service')),
    isPrivate: false,
  },
  {
    path: '/privacy-policy',
    exact: 'true',
    element: lazy(() => import('views/privacy-policy')),
    isPrivate: false,
  },
  {
    path: '/agreement',
    exact: 'true',
    element: lazy(() => import('views/agreement')),
    isPrivate: false,
  },
  {
    exact: 'true',
    path: '/test',
    element: lazy(() => import('views/test')),
    isPrivate: false,
    layout: WebsiteLayout,
  },
  {
    exact: 'true',
    path: '/',
    element: lazy(() => import('views/landing')),
    isPrivate: false,
    layout: WebsiteLayout,
  },
  {
    exact: 'true',
    path: '/mypage',
    element: lazy(() => import('views/mypage')),
    isPrivate: false,
    layout: WebsiteLayout,
  },
  {
    exact: 'true',
    path: '/not-found',
    element: lazy(() => import('views/errors/notFound')),
    isPrivate: false,
    layout: WebsiteLayout,
  },
  {
    path: '*',
    element: lazy(() => import('views/errors/notFound')),
    isPrivate: false,
    layout: WebsiteLayout,
  }
];

export default routes;
