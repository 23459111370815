import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import AxiosService from 'services/Axios';
import { toast } from 'react-toastify';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      try {
        getProfile();
        setIsUserLoggedIn(token);
      } catch (error) {
        toast.error('유효한 토큰이 아닙니다.', error);
//        toast.error('Token is invalid:', error);
      }
    }
    setLoading(false);
  }, []);

  const signIn = async (email, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/signin`, { email, password });
      const { token } = response.data.responseObject;
      setToken(token);
      window.location.href = '/';
    } catch (error) {
      // toast.error('Sign-in failed:', error);
      throw error;
    }
  };

  const signUp = async (formData) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/user/signup`, formData);
      await signIn(formData.email, formData.password);
      window.location.href = `/account?redirect=start`;
    } catch (error) {
      // toast.error('Sign-up failed:', error);
      throw error;
    }
  };

  const updateProfile = async (formData) => {
    try {
      await AxiosService.post(`${process.env.REACT_APP_API_URL}/user/profile`, formData);
      await getProfile();
    } catch (error) {
      // toast.error('Update profile failed:', error);
      throw error;
    }
  };

  const getProfile = async () => {
    try {
      const res = await AxiosService.get(`${process.env.REACT_APP_API_URL}/user/profile`);
      await setUser(res.data.responseObject);
    } catch (error) {
      // toast.error('Failed to fetch data:', error);
      throw error;
    }
  };

  const updateFeedback = async (formData) => {
    try {
      await AxiosService.post(`${process.env.REACT_APP_API_URL}/user/feedback`, formData);
    } catch (error) {
      // toast.error('Failed to fetch data:', error);
      throw error;
    }
  };

  const getFeedback = async (testType) => {
    try {
      const res = await AxiosService.get(`${process.env.REACT_APP_API_URL}/user/feedback/${testType}`);
      return res.data.responseObject;
    } catch (error) {
      // toast.error('Failed to fetch data:', error);
      throw error;
    }
  };

  const signOut = () => {
    Cookies.remove('token');
    setIsUserLoggedIn(false);
    window.location.href = '/signin';
  };

  const setToken = (token) => {
    Cookies.set('token', token, { expires: 7 });
    setIsUserLoggedIn(true);
  }

  const isLoggedIn = () => isUserLoggedIn;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ signIn, signUp, signOut, user, isLoggedIn, updateProfile, getProfile, updateFeedback, getFeedback, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
