import { TEST_TYPES } from "enums/BirthTypes";
import useAuth from "hooks/useAuth";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AxiosService from "services/Axios";

const FortuneContext = createContext();

const FortuneProvider = ({ children }) => {
  const [fortuneData, setFortuneData] = useState({
    [TEST_TYPES.YEAR]: null,
    [TEST_TYPES.DAILY]: null,
    [TEST_TYPES.DOB]: null,
  });
  const { user } = useAuth();

  const getFortuneUrl = (testType) => {
    switch (testType) {
      case TEST_TYPES.YEAR:
        return `${process.env.REACT_APP_API_URL}/user/fortune/2025`;
      case TEST_TYPES.DAILY:
        return `${process.env.REACT_APP_API_URL}/user/fortune/daily`;
      case TEST_TYPES.DOB:
      default:
        return `${process.env.REACT_APP_API_URL}/user/fortune`;
    }
  };

  const getFortune = useCallback(async (testType) => {
    // Check if the fortune data already exists for the requested testType
    if (fortuneData[testType]) {
      return; // If the data already exists, don't make the API call
    }

    try {
      const url = getFortuneUrl(testType);
      const res = await AxiosService.get(url);
      setFortuneData((prevData) => ({
        ...prevData,
        [testType]: res.data.responseObject,
      }));
    } catch (error) {
      toast.error("데이터를 불러오는데 오류가 발생했습니다.", error);
    }
  }, [fortuneData]);

  useEffect(() => {
    if (user?.name && user?.dob) {
      [TEST_TYPES.DOB, TEST_TYPES.YEAR, TEST_TYPES.DAILY].forEach(getFortune);
    }
  }, [user, getFortune]);

  return (
    <FortuneContext.Provider
      value={{
        fortune2025: fortuneData[TEST_TYPES.YEAR],
        dailyTest: fortuneData[TEST_TYPES.DAILY],
        fortune: fortuneData[TEST_TYPES.DOB],
        getFortune,
      }}
    >
      {children}
    </FortuneContext.Provider>
  );
};

export { FortuneContext, FortuneProvider };
